import * as cookie from "react-cookie";

function getDomain() {
  const url = window.location.hostname;

  let hostname = url.split(".").slice(-2);

  return hostname.length === 1 ? "localhost" : `.${hostname.join(".")}`;
}

const osritEnv = process.env.REACT_APP_OSRIT_ENV;
const publicBucket = process.env.REACT_APP_PUBLIC_BUCKET || "osrit-public";

const cookieOpions = {
  path: "/",
  maxAge: 43200,
  domain: getDomain(),
  sameSite: "strict",
  secure: getDomain() !== "localhost"
};

const URLS = {
  CORE_API_KEY: process.env.REACT_APP_CORE_API_KEY,
  CORE_API: process.env.REACT_APP_CORE_API,
  NEXT_API: process.env.REACT_APP_NEXT_API,
  CO_API: process.env.REACT_APP_CO_API,
  CF_API: process.env.REACT_APP_CF_API,
  CS_API: process.env.REACT_APP_CARRIER_SEARCH_API,
  CLOUD_API: process.env.REACT_APP_CLOUD_API,
  APP_NEXT_WEB: process.env.REACT_APP_NEXT_WEB,
  APP_GPS_WEB: process.env.REACT_APP_GPS_WEB,
  APP_COMPLIANCE_WEB: process.env.REACT_APP_COMPLIANCE_WEB,
  APP_ACCOUNTING_WEB: process.env.REACT_APP_ACCOUNTING_WEB,
  APP_RECRUITING_WEB: process.env.REACT_APP_RECRUITING_WEB,
  APP_HOME_WEB: process.env.REACT_APP_HOME_WEB,
  APP_BROKER_WEB: process.env.REACT_APP_BROKER_WEB,
  APP_DHASHCAM_WEB: process.env.REACT_APP_DASHCAM_WEB,
  APP_SIGNUP: process.env.REACT_APP_SIGNUP_WEB,
  APP_LOAD_TRACK_WEB: process.env.REACT_APP_LOAD_TRACK_WEB,
  APP_AUTH: process.env.REACT_APP_AUTH_WEB,
  APP_REPAIR_SHOP_WEB: process.env.REACT_APP_REPAIR_SHOP_WEB
};

const COMPLIANCE_URLS = {
  DRIVER: process.env.REACT_APP_COMPLIANCE_WEB + "/driver/all/",
  TRACTOR: process.env.REACT_APP_COMPLIANCE_WEB + "/fleet/tractor/",
  TRAILOR: process.env.REACT_APP_COMPLIANCE_WEB + "/fleet/trailer/",
  CARRIER: process.env.REACT_APP_COMPLIANCE_WEB + "/carrier/all/",

  ADD_DRIVER: process.env.REACT_APP_COMPLIANCE_WEB + "/driver/all?action=add",
  ADD_TRACTOR: process.env.REACT_APP_COMPLIANCE_WEB + "/fleet/tractor?action=add",
  ADD_TRAILOR: process.env.REACT_APP_COMPLIANCE_WEB + "/fleet/trailer?action=add",
  ADD_CARRIER: process.env.REACT_APP_COMPLIANCE_WEB + "/carrier/all?action=add",
}
const BASE_NAV = {
  SETTING: "/setting",
  ACCOUNTING: "/accounting",
  INTEGRATION: "/integration",
  INVOICE: "/invoice",
  BILLING: "/billing",
  BILLING_DUMP: "/billing-dump",
  COMPANY: "/company",
  ESTIMATES: "/estimates",
  DISPATCH: "/dispatch",
  BROKERAGE: "/brokerage",
  DUMP: "/dump",
  DRIVER: "/driver",
  CARRIER: "/carrier",
  EQUIPMENT: "/equipment",
  TRACKING: "/tracking",
  APPS: "/apps",
  ELD: "/eld",
  USERS: "/setting/users",
  EXPENSE: "/expense",
  PROFILE: "/setting/profile",
  PAYCHECK: "/paycheck",
  DOCUMENTS: "/documents",
  DASHBOARD: "/dashboard",
  SMS: "/sms",
  BUSINESS: "/business",
  BANK_ACCOUNT: "/baccount",
  SHIPPER: "/shippers",
  CHAT: "/chat",
  EMAIL: "/email",
  REPORTS: "/reports",
  TRIPS: "/trips",
  TIMESHEET: "/time-sheet",
  BLACKLISTEDCOMPANT: "/blacklistedcompany",
  TODO: "/todo"
};

const cookies = new cookie.Cookies();

const getAccessToken = function getAccessToken() {
  return cookies.get("token", cookieOpions);
};

const getJwtToken = function getJwtToken() {
  return cookies.get("jwtToken", cookieOpions);
};

const pdfOptions = {
  enableLinks: true,
  margin: [0.5, 0.5, 0.5, 0.5],
  image: { type: "jpeg", quality: 0.98 },
  html2canvas: {
    scale: 2,
    dpi: 192,
    letterRendering: true,
    useCORS: true
  },
  jsPDF: {
    unit: "in",
    format: "letter",
    orientation: "portrait",
    compressPDF: true
  }
};

export {
  osritEnv,
  publicBucket,
  BASE_NAV,
  URLS,
  getAccessToken,
  getJwtToken,
  pdfOptions,
  cookieOpions,
  COMPLIANCE_URLS
};
